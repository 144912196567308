































































































































import { ForEachTarget, Research, Respondent, Target } from '@app/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

import ResearchSummaryList from '../../components/6-other/research-summary-list.vue';
import { ISOdateToNormal } from '../../utils/simple-functions';

@Component({
  components: { ResearchSummaryList },
})
export default class ResearchSummary extends Vue {
  @Prop()
  public value!: Research.Mutable;

  @Prop()
  public respondents!: Respondent[];

  @Prop()
  public targets!: string[];

  public get start() {
    return (
      this.value.date.start &&
      this.value.date.start.toDate().toLocaleDateString()
    );
  }

  public get end() {
    return (
      this.value.date.end && this.value.date.end.toDate().toLocaleDateString()
    );
  }

  public get firstReminder() {
    return ISOdateToNormal(
      this.value.reminder &&
        this.value.reminder[0] &&
        this.value.reminder[0].slice(0, 10)
    );
  }

  public get secondReminder() {
    return ISOdateToNormal(
      this.value.reminder &&
        this.value.reminder[1] &&
        this.value.reminder[1].slice(0, 10)
    );
  }

  public getText(target: Target, source: string | ForEachTarget<string>) {
    const text = typeof source === 'string' ? source : source[target];
    if (!text) return '';

    const getDate = (timestamp?: FirebaseFirestore.Timestamp | null) => {
      return timestamp ? timestamp.toDate().toLocaleDateString() : '';
    };

    const getTooltip = (text: string) => {
      const id = 'var-' + this.$firebase.generateId();
      const tooltip = this.$t('variable-tooltip');

      return [
        `<span class="variable" id="${id}">`,
        `  ${text}`,
        `  <bce-tooltip target="#${id}">`,
        `    ${tooltip}`,
        `  </bce-tooltip>`,
        '</span>',
      ]
        .map(t => t.trim())
        .join('');
    };

    return text
      .replace(/{company:name}/g, this.$company.name || '')
      .replace(/{link}/g, match => getTooltip(match))
      .replace(/{link:([^}]*)}/g, (_, prop) => getTooltip(prop))
      .replace(/{research:title}/g, this.value.title)
      .replace(/{research:start}/g, getDate(this.value.date.start))
      .replace(/{research:end}/g, getDate(this.value.date.end))
      .replace(/{research:response}/g, (this.value.goal || 0) + '%')
      .replace(/{user:([^}]*)}/g, (match, prop) => {
        const valid =
          prop === 'email' ||
          prop === 'firstName' ||
          prop === 'lastName' ||
          this.respondents.some(r => prop in r);
        return valid ? getTooltip(match) : match;
      });
  }
}
