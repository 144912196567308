











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class LicenseCard extends Vue {
  @Prop({ type: String, default: '<name>' })
  public name!: string;

  @Prop({ type: Array, default: () => [] })
  public list!: any[];

  public get values() {
    return this.list.filter(v => !!v);
  }
}
